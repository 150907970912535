@import "~@nematis/wp-alpha/assets/sass/abstracts/functions";
@import "~@fortawesome/fontawesome-pro/scss/functions";
@import "~@fortawesome/fontawesome-pro/scss/variables";

// === Website colors
$color-pompadour: #6d004d;
$color-prim: #f0e5ed;
$color-soft-peach: #faf7f9;

// === Bootstrap
// == Colors
$gray-100: #f5f7fb;
$gray-300: #dde0e6;
$gray-600: #bcbfc5;
$gray-800: #7e828f;
$gray-850: #8d93ab;
$gray-900: #2e374e;
$grays: (
  "100": $gray-100,
  "300": $gray-300,
  "600": $gray-600,
  "800": $gray-800,
  "850": $gray-850,
  "900": $gray-900,
);
$bs-grays-to-keep: ("100", "300", "600", "800", "850", "900");
$primary: $color-pompadour;
$secondary: $gray-800;
$body-bg: $gray-100;
$body-color: $secondary;
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "primary-light": $color-prim,
  "primary-ultra-light": $color-soft-peach,
);

// == Typography
$font-family-sans-serif: "Gotham Book", -apple-system, blinkmacsystemfont,
  "Segoe UI", "Helvetica Neue", arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 1rem;

// == Headings
$headings-font-family: "Gotham", -apple-system, blinkmacsystemfont, "Segoe UI",
  "Helvetica Neue", arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-color: $gray-900;
$h1-font-size: px2rem(20);
$h2-font-size: px2rem(16);
$h3-font-size: px2rem(14);

// == Buttons + Forms
$input-placeholder-color: $gray-600;
$input-reverse-bg: $gray-100;
$input-reverse-border-color: $gray-100;
$input-reverse-color: $body-color;
$input-reverse-placeholder-color: $input-placeholder-color;

// == Components
// = Border radius
$border-radius: px2rem(5px);
$border-radius-lg: px2rem(10px);
$border-radius-sm: px2rem(2.5px);
$border-radius-xl: px2rem(15px);

// == Cards
$card-border-radius: $border-radius-lg;

// == Small
$small-font-size: .75rem;

// == Paragraphs
$paragraph-margin-bottom: 30px;

// == Badges
$badge-font-size: .9rem;
$badge-padding-x: .6em;
$badge-padding-y: .25em;
$badge-border-radius: 10rem;

// == Modals
$modal-backdrop-bg: #fff;

// == Close button
$btn-close-opacity: .75;
$btn-close-hover-opacity: 1;

// === Alpha
// == Config
$menus: ("right", "burger");
$teases: ("grid");

// == Featured image
$home-featured-image-height: calc(100vh - var(--full-header-height-default));
$featured-image-overlay-opacity: .15;

// == Logo
$logo-height: 3rem;
$logo-padding-y: 1.25rem;

// == Overlay
$alpha-overlay-color: $primary;
$alpha-overlay-opacity: .4;

// == Paragraphs
$small-paragraph-margin-bottom: 1rem;

// == Z index
$alpha-z-indexes: (1);

// === Alpha modules
// == Back to top
$back-to-top-offset-y: 3rem;

// === Fonts optimization
// == FontAwesome Icons
$custom-used-fa-icons: (
  // == Medical
  plus: $fa-var-plus,
  // == Travel
  globe-europe: $fa-var-globe-europe
);

$home-featured-image-text-transition: false;
