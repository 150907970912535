@import "config/grid.yaml";
@import "config/templates.yaml";
@import "~@nematis/wp-alpha/assets/sass/abstracts/yaml-variables";
@import "assets/sass/init";
@import "../init";

// === WP ALPHA ASSETS
@import "~@nematis/wp-alpha/assets/sass/pages/home";

// === COMPONENTS
@import "../components/animations";
@import "../components/numerable";

body.home {
  h1,
  .h1 {
    font-size: px2rem(25);

    @include media-breakpoint-up(lg) {
      font-size: px2rem(42);
    }
  }

  h2,
  .h2 {
    font-size: px2rem(25);

    @include media-breakpoint-up(lg) {
      font-size: px2rem(35);
    }
  }

  h3,
  .h3 {
    font-size: px2rem(20px);

    @include media-breakpoint-up(lg) {
      font-size: px2rem(26);
    }
  }

  .home-scroll-to-content-chevron {
    bottom: 2rem;
  }

  &:not(.page-scrolled) {
    .home-scroll-to-content-chevron {
      animation: wad-bounce 1s ease infinite;
    }
  }
}

.featured-image {
  h1 {
    text-shadow: 0 0 5px $black, 0 0 5px $black !important;
  }
}

.home-figures-row {
  min-height: 486px;
  background: $gray-100 url("../../images/bg-top-wave.png") no-repeat top;

  .figure-icon {
    width: 2rem;
  }

  .figure-text {
    font-size: 1rem;

    @include media-breakpoint-up(md) {
      .figure-text {
        font-size: 1.25rem;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .home-how-to-row {
    .app-stores-stacked {
      transform: translateX(-50%) translateZ(0) !important;
    }
  }
}

.home-community-row {
  .bolded {
    @include font-awesome-before($fa-var-quote-left, $margin: map-get($spacers, 1));
    @include font-awesome-after($fa-var-quote-right, $margin: map-get($spacers, 1));

    &::before,
    &::after {
      font-size: 1rem;
      color: $gray-800;

      @include media-breakpoint-up(md) {
        font-size: 1.25rem;
      }
    }
  }
}
