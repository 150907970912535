ol.numerable {
  > li {
    position: relative;
    z-index: 0;

    &:not(:last-child) {
      margin-bottom: map_get($spacers, 4);

      @include media-breakpoint-up(lg) {
        margin-bottom: map_get($spacers, 5);
      }
    }

    text-align: center;
    counter-increment: numerable;

    .list-content {
      position: relative;
      z-index: 10;
    }

    h3 {
      margin-bottom: map_get($spacers, 3);
      font-weight: $font-weight-normal;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      text-align: left;

      .list-content {
        padding-left: 1.5rem;
      }
    }

    &::before,
    &::after {
      font-family: $headings-font-family;
      font-weight: 500;
      line-height: 1;
      content: counter(numerable);
    }

    &::before {
      position: relative;
      z-index: 5;
      font-size: px2rem(40);
      color: $gray-900;
    }

    &::after {
      position: absolute;
      top: -42px;
      left: 0;
      z-index: 0;
      width: 100%;
      font-size: px2rem(190);
      color: rgba($purple, .05);

      @include media-breakpoint-up(lg) {
        font-size: px2rem(170);
      }
    }
  }
}
